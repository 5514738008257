<template>
  <v-container fluid style="padding: 0">
    <ProjectHeader
      :filter="filter"
      @update-filter="updateFilter"
      :activeItemCount="totalActive"
      :archivedItemCount="totalArchived"
    />
    <v-card class="py-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <template v-if="projects.length > 0 || projects.length == 0 && filter == 'archived' || loading">
        <v-row justify="space-between" class="align-center">
          <v-col cols="12" md="auto" class="d-flex align-center">
            <SearchComponent :search="search" @update:search="search = $event"></SearchComponent>
            <ProjectFilter></ProjectFilter>
          </v-col>
          <v-col cols="12" md="auto">
            <v-row justify="end" class="align-center">
              <ToggleView :table="table" @toggle-table="toggleTable" />
              <SettingsMenu :filterItems="filterItems" :requiredItems="['Name', 'Test Runs', 'Users']" />
            </v-row>
          </v-col>
        </v-row>
        <ProjectTable
          :filteredHeaders="filteredHeaders"
          :filteredItems="filteredItems"
          :itemKey="itemKey"
          :rowClass="rowClass"
          @select-item="useProject"
          @edit-item="editItem"
          @archive-item="confirmArchiveProject"
          @unarchive-item="confirmUnarchiveProject"
          @delete-item="confirmDeleteProject"
          @toggle-star="handleToggleStar"
          v-if="table"
        />
        <v-row v-else class="mt-6">
          <v-col v-for="(item, index) in filteredItems" :key="index" cols="4">
            <ProjectCard
              :item="item"
              :filterItems="filterItems"
              @select-item="useProject"
              @edit-item="editItem"
              @archive-item="confirmArchiveProject"
              @unarchive-item="confirmUnarchiveProject"
              @delete-item="confirmDeleteProject"
              @toggle-star="handleToggleStar"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="projects.length == 0 && !loading && filter == 'active'">
        <v-row class="py-sm-16">
          <v-col cols="12" class="d-flex justify-center">
            <v-img
              :src="require('@/assets/png/table-empty-state.png')"
              alt="Table empty state"
              width="100%"
              max-width="438"
            />
          </v-col>
          <v-col cols="12">
            <v-flex class="flex-column">
              <p class="ma-0 font-weight-bold text-h6 text-sm-h5">{{ $t('projects.empty_state.title') }}</p>
              <p class="mb-0 mt-3">{{ $t('projects.empty_state.lets_get_started') }}</p>
              <p class="ma-0">
                {{ $t('projects.empty_state.take_the_lead.part1') }}
                <span class="font-weight-medium">{{ $t('projects.empty_state.take_the_lead.part2') }}</span>
                {{ $t('projects.empty_state.take_the_lead.part3') }}
              </p>
            </v-flex>
            <v-flex class="mt-6">
              <v-btn
                dark
                large
                color="blue"
                class="text-capitalize font-weight-bold"
                :to="{ name: 'ProjectCreateView' }"
              >
                {{ $t('createProject') }} <v-icon class="ml-1" size="16">mdi-plus</v-icon>
              </v-btn>
            </v-flex>
          </v-col>
        </v-row>
      </template>
    </v-card>
    <ProjectDiscardDialog
      :title="$t('projects.delete_project_dialog.title')"
      v-model="showConfirmDeleteDialog"
      @close="closeDeleteDialog"
      @handleConfirmClick="confirmDeleteProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.delete_project_dialog.warning') }}
          </p>
          <p class="text-start">
            {{ $t('projects.delete_project_dialog.recommend') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="archiveProject"
            >
              {{ $t('archive') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="danger"
              @click="deleteProject"
            >
              {{ $t('projects.create_project.close_dialog.confirm_button') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
    <ProjectDiscardDialog
      :title="$t('projects.archive_project_dialog.title')"
      v-model="showConfirmArchiveDialog"
      @close="closeArchiveDialog"
      @handleConfirmClick="confirmArchiveProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.archive_project_dialog.warning') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="closeArchiveDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="blue"
              @click="archiveProject"
            >
              {{ $t('archive') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
    <ProjectDiscardDialog
      :title="$t('projects.archive_project_dialog.title')"
      v-model="showConfirmUnarchiveDialog"
      @close="closeUnarchiveDialog"
      @handleConfirmClick="confirmUnarchiveProject"
    >
      <template v-slot:content>
        <v-flex class="mt-4">
          <p class="text-start">
            {{ $t('projects.archive_project_dialog.warning') }}
          </p>
        </v-flex>
      </template>
      <template v-slot:footer>
        <v-row>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold black--text mt-2"
              color="gray-100"
              @click="closeUnarchiveDialog"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              dark
              large
              width="100%"
              class="text-capitalize font-weight-bold white--text mt-2"
              color="blue"
              @click="unarchiveProject"
            >
              {{ $t('unarchive') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ProjectDiscardDialog>
  </v-container>
</template>

<script>
import ProjectHeader from '@/components/Project/ProjectHeader';
import ProjectFilter from '@/components/Project/ProjectFilter.vue';
import SearchComponent from '@/components/Project/SearchComponent.vue';
import ProjectCard from '@/components/Project/ProjectCard.vue';
import ToggleView from '@/components/Project/ToggleView.vue';
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import ProjectTable from '@/components/Project/ProjectTable.vue';
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog';
import { createNamespacedHelpers } from 'vuex';
import ProjectsService from '@/services/api/project';
import { handleNetworkStatusError } from '@/mixins/redirect';

const { mapState: mapUserState } = createNamespacedHelpers('user');
import { showSuccessToast, showErrorToast } from '@/utils/toast';

import { items } from '@/constants/data.js';

let makeProjectService

export default {
  name: 'Project',
  mixins: [handleNetworkStatusError],
  components: {
    ProjectHeader,
    ProjectFilter,
    SearchComponent,
    ProjectCard,
    ProjectTable,
    ToggleView,
    SettingsMenu,
    ProjectDiscardDialog,
  },
  data() {
    return {
      handle: this.$route.params.handle,
      selectedProject: {},
        projects: [],
      totalActive: 0,
      totalArchived: 0,
      filter: 'active',
      search: '',
      items: items,
      table: true,
      options: ['active', 'archived'],
      filterItems: [
        { text: this.$t('name'), checked: true },
        { text: this.$t('key'), checked: true },
        { text: this.$t('testRuns'), checked: true },
        { text: this.$t('testCases'), checked: false },
        { text: this.$t('defects'), checked: false },
        { text: this.$t('projectAdmin'), checked: false },
        { text: this.$t('creationDate'), checked: false },
        { text: this.$t('lastChanges'), checked: false },
        { text: this.$t('users'), checked: true },
      ],
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'name',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('key'),
          align: 'start',
          sortable: true,
          value: 'key',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('testRuns'),
          value: 'test',
          sortable: true,
        },
        {
          text: this.$t('testCases'),
          value: 'cases',
          sortable: true,
        },
        {
          text: this.$t('defects'),
          value: 'defects',
          sortable: true,
        },
        {
          text: this.$t('projectAdmin'),
          value: 'projadmin',
          sortable: true,
        },
        {
          text: this.$t('lastChanges'),
          value: 'lastchanges',
          sortable: true,
        },
        {
          text: this.$t('creationDate'),
          value: 'creationdate',
          sortable: true,
        },
        {
          text: this.$t('users'),
          value: 'users',
          sortable: true,
          class: 'rounded-r-lg',
        },
      ],
      itemKey: 'uid',
      rowClass: () => 'project-item',
      showConfirmDeleteDialog: false,
      showConfirmArchiveDialog: false,
      showConfirmUnarchiveDialog: false,
      loading: false,
    };
  },
  computed: {

    ...mapUserState(['currentAccount']),
    filteredHeaders() {
      return this.headers.filter((header) => {
        const filterItem = this.filterItems.find((item) => item.text === header.text);
        return filterItem ? filterItem.checked : true;
      });
    },
    filteredItems() {
      let filtered = this.projects;
      if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter((project) => project.name.toLowerCase().includes(searchTerm));
      }
      return filtered;
    },
  },
    mounted(){
    this.getProjects();
  },
  created() {
      makeProjectService = ProjectsService(this.$api);
  },
  methods: {

    useProject(project) {
      this.$router.push({
        name: 'Cases',
        params: {
          handle: this.$route.params.handle,
          key: project.key
        },
      });
    },
    
  async getProjects() {
      const searchParams = new URLSearchParams();
      searchParams.set('status', this.filter);
      searchParams.set('includeCount', true); 
      this.loading = true;
      try {
        const response = await makeProjectService.getProjects(this.handle, searchParams.toString());

        this.projects = response.data.projects;
        this.totalActive = response.data.meta.totalActive;
        this.totalArchived = response.data.meta.totalArchived;
      } catch (error) {
          this.redirectOnError(error.response.status)
          showErrorToast(this.$swal, 'fetchError', { item: 'projects' });
      } finally {
        this.loading = false;
      }
    },
    restore() {
      this.filterItems.forEach((item) => {
        item.checked = ['Name', 'Test Runs', 'Users'].includes(item.text);
      });
    },
    archiveItem(item) {
      item.status = 'archived';
    },
    async updateFilter(newFilter) {
      this.filter = newFilter;
      await this.getProjects();
    },
    toggleTable(table) {
      this.table = table;
    },
    closeDeleteDialog() {
      this.showConfirmDeleteDialog = false;
      this.selectedProject = null;
    },
    confirmDeleteProject(project) {
      this.showConfirmDeleteDialog = true;
      this.selectedProject = project;
    },
    async deleteProject() {
      try {
        await makeProjectService.deleteProject(this.handle, this.selectedProject.key);
          showSuccessToast(this.$swal, 'deleteSuccess', { item: 'projects' })
        this.getProjects();
      } catch (error) {
          showErrorToast(this.$swal, 'deleteError', { item: 'projects' });
      } finally {
        this.showConfirmDeleteDialog = false;
          this.closeDeleteDialog();
      }
    },

    async archiveProject(){
              const payload = {
        customFields: {
          ...this.selectedProject.customFields,
        },
        archived: true,
      };
      try {
        await makeProjectService.updateProject(this.handle, this.selectedProject.key, payload);
          showSuccessToast(this.$swal, 'archiveSuccess', { item: 'Project' });
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'archiveError', { item: 'Project' });
      } finally {
        this.showConfirmArchiveDialog = false;
      }
    },

    async unarchiveProject(){
          const payload = {
        customFields: {
          ...this.selectedProject.customFields,
        },
        archived: false,
      };
      try {
      await makeProjectService.updateProject(this.handle, this.selectedProject.key, payload);
        showSuccessToast(this.$swal, 'unarchiveSuccess', { item: 'Project' });
        this.getProjects();
      } catch (error) {
        showErrorToast(this.$swal, 'unarchiveError', { item: 'Project' });
      } finally {
        this.showConfirmUnarchiveDialog = false;
      }
    },
    closeArchiveDialog() {
      this.showConfirmArchiveDialog = false;
      this.selectedProject = null;
    },
    confirmArchiveProject(project) {
      this.showConfirmArchiveDialog = true;
      this.selectedProject = project;
    },
    closeUnarchiveDialog() {
      this.showConfirmUnarchiveDialog = false;
      this.selectedProject = null;
    },
    confirmUnarchiveProject(project) {
      this.showConfirmUnarchiveDialog = true;
      this.selectedProject = project;
    },
    editItem(project) {
      this.selectedProject = project;
      this.$router.push({
        name: 'ProjectDetailView',
        params: {
          handle: this.$route.params.handle,
          key: project.key,
        },
      });
    },
    handleToggleStar(project) {
      this.selectedProject = project;
      const payload = {
        customFields: {
          ...this.selectedProject.customFields,
          star: !this.selectedProject.customFields.star,
        },
      };
      this.update({
        swal: this.$swal,
        handle: this.$route.params.handle,
        oldProject: this.selectedProject,
        payload,
      });
    },
    handleRouteChange(to, from, next) {
      this.getProjects(to.params.handle);
      next();
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(to, from, next);
  },
};
</script>
